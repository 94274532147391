<template>
  <el-dialog title="日志" width="80%" top="30px" v-model="visible" @close="handleCloseDialog" destroy-on-close>
    <div class="log-container">
      <div class="log-head">
        <div class="log-head__item">
          <el-select v-model="logType" placeholder="请选择" @change="handleTypeChange">
            <el-option v-for="type in logTypes" :key="type.key" :label="type.name" :value="type.key"></el-option>
          </el-select>
        </div>
        <div class="log-head__item">
          <el-button class="btn" title="刷新" @click="handleRefresh" :disabled="loading">
            <svg-icon icon-name="refresh" class="icon"></svg-icon>
          </el-button>
        </div>
      </div>
      <div
        class="log-body"
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-background="rgba(0, 0, 0, 0)"
        ref="refLogContent"
      >
        <template v-if="!loading">
          <p class="log-empty" v-if="isError">获取日志失败...</p>
          <p class="log-empty" v-else-if="isEmpty">暂无日志...</p>
        </template>
        <template v-if="!isError && !isEmpty">
          <p class="log-item" v-for="(log, index) in logs" :key="index" v-html="formatLogData(log)"></p>
        </template>
      </div>
    </div>
    <div style="text-align: center">
      <el-button @click="handleCloseDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
/* eslint-disable vue/no-parsing-error */
import { defineComponent, reactive, ref } from 'vue';
import { formatLogData } from '@/views/service-management/business-service/utils/service-log-data-utils';
import { getLogs, getServiceTraceId } from '@/api/servers';
import dateFormat from '@/utils/date-format';
// 最多显示日志条数
const MAX_LOG_ITEMS = 50000;
// 加载为空时，多少次后不再主动加载，而是点击刷新按钮
const MAX_LOG_EMPTY_TIMES = 6;
// 轮询时间
const LOOP_TIMEOUT = 5000;
// 编译日志的tag
const LOG_TYPE_COMPILE = '_COMPILE_';

const REG_COMPILE = new RegExp(` ${LOG_TYPE_COMPILE} `, 'gmi');

const REG_TIME = /\[(\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}.\d{3})\]/gim;

const LOG_LIMIT = 100;

const logTypes = [
  {
    key: 'all',
    name: '全部日志',
  },
  {
    key: LOG_TYPE_COMPILE,
    name: '编译日志',
  },
];
export default defineComponent({
  name: 'LogDialog',
  props: {
    serviceId: {
      type: Number,
      default: 0,
    },
    serviceType: {
      type: String,
      default: 'BACKEND',
    },
  },
  setup(props) {
    const visible = ref(false);
    const loading = ref(false);
    const logType = ref('all');
    const fetchEmptyTimes = ref(0);
    const isEmpty = ref(false);
    const isError = ref(false);
    const loopTimer = ref(null);
    const startTime = ref('');
    const refLogContent = ref('');
    const params = reactive({
      traceId: '',
      traceNode: '',
    });
    const logs = ref([]);

    const clearTimer = () => {
      clearTimeout(loopTimer.value);
      loopTimer.value = null;
    };

    const setTimer = () => {
      loopTimer.value = setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        fetchLog();
      }, LOOP_TIMEOUT);
    };

    // 获取traceId
    const fetchTraceId = async () => {
      isError.value = false;
      startTime.value = '';
      try {
        const { code, data } = await getServiceTraceId(props.serviceId, props.serviceType);
        if (code === 0) {
          params.traceId = data.traceId;
          params.traceNode = data.traceNode;
          if (data.realtimeTs) {
            startTime.value = dateFormat(data.realtimeTs * 1, '-');
          }
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          await fetchLog();
        }
      } catch (e) {
        console.log(e);
        isError.value = true;
      }
    };

    const fetchLog = async () => {
      isError.value = false;
      clearTimer();
      // 没有traceID的情况下不加载日志
      if (!params.traceId) {
        isEmpty.value = true;
        loading.value = false;
        return;
      }
      const searchKeywords = [params.traceId];
      if (logType.value === LOG_TYPE_COMPILE) {
        searchKeywords.push(LOG_TYPE_COMPILE);
      }
      const sT = startTime.value;
      const endTime = sT ? dateFormat(Date.now(), '-') : '';
      const fetchParams = {
        serviceType: props.serviceType,
        name: params.traceNode,
        keywords: searchKeywords,
        limit: LOG_LIMIT,
      };
      if (sT && endTime) {
        fetchParams.startTime = sT;
        fetchParams.endTime = endTime;
      }
      const currentLogList = [];
      try {
        const { code, data, message } = await getLogs(fetchParams);
        if (code === 0) {
          const items = (data.content || []).map((item) => item.content.replace(REG_COMPILE, ''));
          const lastLog = items[items.length - 1] || '';
          const timeMatch = lastLog.match(REG_TIME);
          if (timeMatch?.[0]) {
            const time = timeMatch[0].replace(/^\[/, '').replace(/\]$/, '');
            startTime.value = dateFormat(time, '-');
          }
          const originLastLogList = logs.value.slice(logs.value.length - LOG_LIMIT);
          items.forEach((item) => {
            if (!originLastLogList.includes(item)) {
              currentLogList.push(item);
            }
          });
        } else {
          throw message;
        }
      } catch (e) {
        console.log(e);
        isError.value = true;
        isEmpty.value = false;
        loading.value = false;
        return;
      }
      let logList = [...logs.value, ...currentLogList];
      // 超出限制时，删除头部
      if (logList.length > MAX_LOG_ITEMS) {
        logList = logList.slice(logList.length - MAX_LOG_ITEMS);
      }
      logs.value = logList;
      const { length } = logs.value;
      try {
        const $el = refLogContent.value;
        $el.scrollTop = $el.scrollHeight;
      } catch (error) {
        console.log(error);
      }

      if (length === 0) {
        // 超过加载空次数限制时，不再加载
        if (fetchEmptyTimes.value >= MAX_LOG_EMPTY_TIMES) {
          isEmpty.value = true;
          loading.value = false;
        } else {
          fetchEmptyTimes.value = fetchEmptyTimes.value + 1;
          loading.value = true;
        }
      } else {
        fetchEmptyTimes.value = 0;
        isEmpty.value = false;
        loading.value = false;
      }
      console.log(isEmpty.value);
      if (!isEmpty.value) {
        setTimer();
      }
    };

    const handleCloseDialog = () => {
      visible.value = false;
      fetchEmptyTimes.value = 0;
      startTime.value = '';
      logs.value = [];
      clearTimer();
    };

    const handleOpenDialog = async () => {
      visible.value = true;
      logType.value = 'all';
      // 使用宏任务将参数赋值的时间线拉在调用之前
      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleRefresh();
      }, 100);
    };

    // 刷新
    const handleRefresh = async () => {
      fetchEmptyTimes.value = 0;
      loading.value = true;
      isEmpty.value = false;
      logs.value = [];
      await fetchTraceId();
    };

    // 类型改变
    const handleTypeChange = async (value) => {
      logType.value = value;
      handleRefresh();
    };

    return {
      visible,
      loading,
      logType,
      logTypes,
      logs,
      isEmpty,
      isError,
      handleCloseDialog,
      handleOpenDialog,
      formatLogData,
      handleTypeChange,
      handleRefresh,
      refLogContent,
    };
  },
});
</script>
<style lang="scss" scoped>
.log-container {
  .log-head {
    margin-top: -20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    &__item {
      margin-left: 10px;
    }
    .btn {
      display: flex;
      width: 30px;
      height: 30px;
      padding: 0;
      align-items: center;
      justify-content: center;
      svg {
        vertical-align: middle;
        width: 16px;
        height: 16px;
      }
    }
  }
  .log-body {
    width: 100%;
    height: 70vh;
    overflow-y: auto;
    font-size: 13px;
    background-color: rgb(38, 38, 38);
    color: rgb(187, 187, 187);
    box-shadow: 0 2px 3px 0 rgb(0 0 0 / 20%);
    margin-bottom: 30px;
    padding: 10px;
    position: relative;
    .log-item {
      line-height: 20px;
      margin: 5px 0;
      padding: 0;
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
    }
    .log-empty {
      margin: 100px 0;
      text-align: center;
    }
    :deep .el-loading-mask {
      background-color: rgba(0, 0, 0, 0);
    }
    :deep .el-loading-spinner {
      .circular {
        width: 32px;
        height: 32px;
      }
      .el-loading-text {
        font-size: 12px;
      }
    }
  }
}
</style>
