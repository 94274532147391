import { ref } from 'vue';
import AnsiUp from 'ansi_up';

export const logDialogVisible = ref(false);
export const logData = ref([] as any);
export const timeout = ref(null as any);
export const realtime = ref('' as any);

export function formatLogData(str: string) {
  const ansiUp = new AnsiUp();
  return ansiUp.ansi_to_html(str).replace(/\n/gm, '<br/>');
}
