
// @ts-nocheck
/* eslint-disable */
import {ElMessage} from "element-plus";
import {getDomainConfig} from "@/api/servers";
import { defineComponent, ref, watch} from 'vue';
import { appBaseData, domainData, domainVisible, serviceBaseData, ServiceType } from "@/views/service-management/business-service/components/domainConfig/dataCenter";
import useTenant from '@/views/tenant-management/useTenant';


export default defineComponent({
  name: 'DefineConfig',
  props: {
    serviceType: {
      type: String,
      default: ServiceType.BACKEND
    },
    serviceId: {
      type: Number,
      default: 0
    },
    onStart: {
      type: Function,
      default: () => {
        return (data: any) => {
          console.log(data)
        }
      }
    }
  },
  setup(props) {
    // loading参数
    const loading = ref(false);
    const submitting = ref(false);

    const baseData = props.serviceType === ServiceType.BACKEND ? serviceBaseData : appBaseData

    const handleStart = (data: any) => {
      for (let k in data) {
        data[k] = Number(data[k])
      }
      submitting.value = true;
      props.onStart(data)
    }

    // 切换显示隐藏
    const toggleVisible = () => {
      submitting.value = false;
      domainVisible.value = false;
    };
    // inject tenant development
    const {tenant,fetchTenantInfo} = useTenant()
    if(!tenant.value) {
      fetchTenantInfo()
    }

    watch(domainVisible, (nn) => {
      if (nn) {
        loading.value = true;
        // 获取当前服务的上次配置
        getDomainConfig({
          serviceType: props.serviceType,
          serviceId: props.serviceId
        }).then(res => {
          const { data } = res?.data;
          if (data) {
            domainData.value = data;
          } else {
            domainData.value = baseData;
            ElMessage.info('当前服务未经过配置或者未配置成功，已填充默认值以供参考');
          }
          loading.value = false;
        }).catch(e => {
          console.log(e);
          loading.value = false;
          domainData.value = baseData;
        })
      }
    })
    const numberCheck = (num: any, limit: number, request: number, hasPoint = true) => {
      let str = num.toString();
      const len1 = str.substr(0, 1);
      const len2 = str.substr(1, 1);
      // 如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 !== '.') {
        str = str.substr(1, 1);
      }
      // 第一位不能是.
      if (len1 === '.') {
        str = '';
      }
      // 限制只能输入一个小数点
      if (str.indexOf('.') !== -1) {
        const strCopy = str.substr(str.indexOf('.') + 1);
        if (strCopy.indexOf('.') !== -1) {
          str = str.substr(0, str.indexOf('.') + strCopy.indexOf('.') + 1);
        }
      }
      // 正则替换，保留数字和小数点
      const point = hasPoint ? /[^\d^.]+/g : /[^\d^]+/g
      str = str.replace(point, '');
      // 如果需要保留小数点后两位，则用下面公式
      str = str.replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
      if (+str > limit) {
        str = `${limit}`;
      }
      if (+str < request) {
        console.log(str, request)
        str = `${request}`;
      }
      return str;
    };

    const checkCpuRequest = (nn: any) => {
      domainData.value.cpuRequest = numberCheck(nn.target.value, 64, 0.01);
    }
    const checkCpuLimit = (nn: any) => {
      domainData.value.cpuLimit = numberCheck(nn.target.value, 64, 0.01);
    }

    const checkMemRequest = (nn: any) => {
      domainData.value.memRequest = numberCheck(nn.target.value, 32000, 4, false);
    }

    const checkMemLimit = (nn: any) => {
      domainData.value.memLimit = numberCheck(nn.target.value, 32000, 4, false);
    }

    watch(
      () => domainData.value.cpuLimit,
      (nn) => {
          domainData.value.cpuLimit = numberCheck(nn, 64, 0);
      },
    );
    watch(
      () => domainData.value.cpuRequest,
      (nn) => {
          domainData.value.cpuRequest = numberCheck(nn, 64, 0);
      },
    );
    watch(
      () => domainData.value.memLimit,
      (nn) => {
        domainData.value.memLimit = numberCheck(nn, 32000, 0, false);
      },
    );
    watch(
      () => domainData.value.memRequest,
      (nn) => {
        domainData.value.memRequest = numberCheck(nn, 32000, 0, false);
      },
    );
    const resetBtnClick = async () => {
      domainData.value = baseData;
      handleStart(baseData);
      toggleVisible();
    };
    const entryBtnClick = async () => {
      const subData = { ...domainData.value } as any
      if(subData.cpuRequest === '' || subData.cpuLimit === '' || subData.memLimit === '' || subData.memRequest === '') {
        return ElMessage.error('不可有空值！');
      }
      let cpuLabel = '';
      let insideLabel = '';
      if (+subData.cpuLimit < +subData.cpuRequest) {
        cpuLabel = 'CPU';
      }
      if (+subData.memLimit < +subData.memRequest) {
        insideLabel = '内存';
      }
      if ((cpuLabel + insideLabel) !== '') {
        return ElMessage.error(`${cpuLabel}  ${insideLabel}最小值不可大于最大值，请重新输入！`);
      }
      handleStart(domainData.value);
      toggleVisible();
    };
    const handleStartWithoutDomainInfo = () => {
      handleStart(baseData)
    }
    return {
      domainVisible,
      domainData,
      toggleVisible,
      numberCheck,
      resetBtnClick,
      entryBtnClick,
      checkCpuRequest,
      checkCpuLimit,
      checkMemLimit,
      checkMemRequest,
      submitting,
      handleStartWithoutDomainInfo,
    };
  },
});
