/* eslint-disable */
import { ref } from 'vue';

interface DomainData {
  cpuLimit: number;
  cpuRequest: number;
  memRequest: number;
  memLimit: number;
}

export const serviceBaseData: DomainData = {
  cpuRequest: 0.1,
  cpuLimit: 0.5,
  memRequest: 100,
  memLimit: 1024,
};

export const appBaseData: DomainData = {
  cpuRequest: 0.1,
  cpuLimit: 0.5,
  memRequest: 20,
  memLimit: 512,
}

export enum ServiceType  {
  BACKEND= 'BACKEND',
  WEB= 'WEB'
}

export const domainVisible = ref(false);

export const domainData = ref(serviceBaseData);

export const openDomainDialog = (data?: DomainData) => {
  domainVisible.value = true;
  if (data) {
    domainData.value = data;
  }
}

